import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Button as MuiButton } from '@material-ui/core';
import { buttonStyle } from './ButtonStyle';
import { Link } from 'gatsby';

export interface CustomButtonProps {
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose' | 'white' | 'transparent';
  size?: 'sm' | 'lg';
  simple?: boolean;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  fileButton?: boolean;
  className?: string;
  plain?: boolean;
  background?: boolean;
  children: ReactNode;
  component?: typeof Link; // TODO: lots of tricks here...maybe something here better? https://material-ui.com/guides/typescript/#usage-of-component-prop
  to?: string; // TODO: lots of tricks here to pass the GatsbyLinks props...any better idea?
}

const useStyles = makeStyles(buttonStyle);

export const Button = React.forwardRef<HTMLButtonElement, CustomButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  (props, ref) => {
    const {
      color,
      round,
      children,
      fullWidth,
      disabled,
      simple,
      size,
      block,
      link,
      justIcon,
      fileButton,
      className,
      ...rest
    } = props;
    const classes = useStyles();
    const btnClasses = classNames({
      [classes.button]: true,
      [classes[size!]]: size,
      [classes[color!]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [classes.fileButton]: fileButton,
      [className!]: className,
    });

    return (
      <MuiButton {...rest} ref={ref} className={btnClasses}>
        {children}
      </MuiButton>
    );
  },
);
