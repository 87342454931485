/* eslint-disable no-case-declarations */
import { ProductNode } from '../../interfaces/contentful';

export type AddToCartAction = {
  readonly type: 'cart/addToCart';
  payload: {
    quantity: number;
    newProduct: ProductNode;
  };
};

export type RemoveFromCartAction = {
  readonly type: 'cart/removeFromCart';
  payload: {
    quantity: number;
    newProduct: ProductNode;
  };
};

export type ClearCartAction = {
  readonly type: 'cart/clearCart';
};

export type Actions = AddToCartAction | RemoveFromCartAction | ClearCartAction;

export interface ShoppingCartProducts {
  [key: string]: ProductNode & { quantity: number };
}

export type State = {
  products: ShoppingCartProducts;
};

const getCachedState = (): State | null => {
  let state = null;
  if (typeof window !== `undefined` && window.localStorage.getItem('cart') !== null) {
    state = JSON.parse(window.localStorage.getItem('cart')!);
  }

  return state;
};

// TODO: verify if this can be done safer...and more beautiful
export const initialState: State = {
  products: getCachedState() ? getCachedState()!.products : {},
};

export function cartReducer(state: State, action: Actions): State {
  switch (action.type) {
    case 'cart/clearCart':
      const updatedClearCartState = {
        ...state,
        products: {},
      };
      window.localStorage.setItem('cart', JSON.stringify(updatedClearCartState));
      return updatedClearCartState;
    case 'cart/addToCart':
      const updatedState = {
        ...state,
        products: {
          ...state.products,
          [action.payload.newProduct.contentful_id]: {
            ...state.products[action.payload.newProduct.contentful_id],
            ...action.payload.newProduct,
            quantity: state.products[action.payload.newProduct.contentful_id]
              ? state.products[action.payload.newProduct.contentful_id].quantity + action.payload.quantity
              : action.payload.quantity,
          },
        },
      };

      window.localStorage.setItem('cart', JSON.stringify(updatedState));
      return updatedState;
    case 'cart/removeFromCart':
      if (state.products[action.payload.newProduct.contentful_id]) {
        if (state.products[action.payload.newProduct.contentful_id].quantity <= action.payload.quantity) {
          const { [action.payload.newProduct.contentful_id]: _, ...others } = state.products;
          // console.log('toRemove', action.payload.newProduct.contentful_id, state.products);

          const updatedState = {
            ...state,
            products: {
              ...others,
            },
          };

          window.localStorage.setItem('cart', JSON.stringify(updatedState));
          return updatedState;
        } else {
          const updatedState = {
            ...state,
            products: {
              ...state.products,
              [action.payload.newProduct.contentful_id]: {
                ...state.products[action.payload.newProduct.contentful_id],
                ...action.payload.newProduct,
                quantity: state.products[action.payload.newProduct.contentful_id].quantity - action.payload.quantity,
              },
            },
          };
          window.localStorage.setItem('cart', JSON.stringify(updatedState));
          return updatedState;
        }
      } else {
        const updatedState = {
          ...state,
          products: {
            ...state.products,
          },
        };
        window.localStorage.setItem('cart', JSON.stringify(updatedState));
        return updatedState;
      }
    default:
      throw new Error();
  }
}
