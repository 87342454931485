import React from 'react';
import { Layout } from './src/components/layout';
import { IntlProvider } from 'react-intl';

import messagesNL from './src/translations/nl.json';
import { StateProvider } from './src/store/StateProvider';
import { CookiesProvider } from 'react-cookie';

export const wrapRootElement = ({ element, pathname }) => {
  return (
    <CookiesProvider>
      <IntlProvider locale="nl-NL" messages={messagesNL}>
        <StateProvider>{element}</StateProvider>
        {/*<Layout location={pathname}>{element}</Layout>*/}
      </IntlProvider>
    </CookiesProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const { path } = props;

  if (path === '/zie-onzichtbaar') {
    return element;
  }

  return <Layout location={path}>{element}</Layout>;
};

/**
 * @param {RouteUpdateArgs} args
 *
 * @see more https://developers.google.com/analytics/devguides/collection/gtagjs/pages#pageview_parameters
 */
// export const onRouteUpdate = (args) => {
//   /* eslint-disable @typescript-eslint/camelcase */
//   const sendPageView = () => {
//     window.gtag &&
//       window.gtag('event', 'page_view', {
//         page_title: document.title,
//         page_location: args.location.href,
//         page_path: args.location.pathname,
//       });
//   };
//   /* eslint-enable @typescript-eslint/camelcase */
//
//   // @see for inspiration https://github.com/gatsbyjs/gatsby/pull/12033/files
//   if ('requestAnimationFrame' in window) {
//     requestAnimationFrame(() => {
//       requestAnimationFrame(sendPageView);
//     });
//   } else {
//     setTimeout(sendPageView, 32);
//   }
// };
