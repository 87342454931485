import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, List, ListItem } from '@material-ui/core';
import { theme } from '../assets/jss/theme';
import { Footer } from './Footer/Footer';
import { Link as GatsbyLink } from 'gatsby';
import { footerStyle } from './Footer/FooterStyle';
import { FormattedMessage } from 'react-intl';
import { CookieBar } from './Cookie/CookieBar';

interface LayoutProps {
  location: string;
}

const useStyles = makeStyles(footerStyle);

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
      <Footer
        theme="dark"
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/legal/algemene-voorwaarden`} className={classes.block}>
                    <FormattedMessage id="legal.generalConditions" defaultMessage="General Conditions" />
                  </GatsbyLink>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/legal/disclaimer`} className={classes.block}>
                    <FormattedMessage id="legal.disclaimer" defaultMessage="Disclaimer" />
                  </GatsbyLink>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/legal/privacy-verklaring`} className={classes.block}>
                    <FormattedMessage id="legal.privacyStatement" defaultMessage="Privacy statement" />
                  </GatsbyLink>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/legal/cookie-verklaring`} className={classes.block}>
                    <FormattedMessage id="legal.cookieverklaring" defaultMessage="Cookie statement" />
                  </GatsbyLink>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <GatsbyLink to={`/qr`} className={classes.block}>
                    QR
                  </GatsbyLink>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {new Date().getFullYear()},{' '}
              <a href="https://tekmi.nl" target="_blank" rel="noopener noreferrer">
                TekMi
              </a>
            </div>
          </div>
        }
      />
      <CookieBar />
    </ThemeProvider>
  );
};
