import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThumbUp } from '@material-ui/icons';

import cookieBarStyle from './CookieBarStyle';
import { Link as GatsbyLink } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import { Button } from '../Button/Button';
import { useCookies } from 'react-cookie';

const useStyles = makeStyles(cookieBarStyle);

export const CookieBar: React.FC = () => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(['CookieStatementAccepted']);

  const rememberAcceptingCookieStatement = () => {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + 365 * 24 * 60 * 60 * 1000);
    setCookie('CookieStatementAccepted', true, { path: '/', expires: expiryDate });
  };

  if (cookies['CookieStatementAccepted']) {
    return null;
  }

  return (
    <div className={classes.cookieBar}>
      <div>
        Wij gebruiken functionele cookies om je een optimale gebruikerservaring te bieden. <br />
        Meer informatie vindt u in onze{' '}
        <GatsbyLink to={`/legal/cookie-verklaring`}>
          <FormattedMessage id="legal.cookieverklaring" defaultMessage="Cookie statement" />
        </GatsbyLink>
        .
      </div>
      <div>
        <Button color="rose" onClick={rememberAcceptingCookieStatement}>
          <ThumbUp style={{ fontSize: 25 }} />
        </Button>
      </div>
    </div>
  );
};
