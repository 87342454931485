import React, { createContext, useReducer, Dispatch } from 'react';
import { Actions, cartReducer, initialState } from './reducers/CartReducer';

export const CartDispatchContext = createContext({} as Dispatch<Actions>);
export const CartStateContext = createContext(initialState);

export const StateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialState);

  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartStateContext.Provider value={state}>{children}</CartStateContext.Provider>
    </CartDispatchContext.Provider>
  );
};
