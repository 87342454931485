import { blackColor, whiteColor, grayColor, hexToRgb } from '../../assets/jss/materialKitBase';
import { createStyles } from '@material-ui/core';

export const cookieBarStyle = () =>
  createStyles({
    cookieBar: {
      border: '0',
      borderRadius: '3px',
      padding: '1rem',
      // marginBottom: '20px',
      color: grayColor[15],
      width: '100%',
      backgroundColor: whiteColor,
      boxShadow:
        '0 4px 18px 0px rgba(' +
        hexToRgb(blackColor) +
        ', 0.12), 0 7px 10px -5px rgba(' +
        hexToRgb(blackColor) +
        ', 0.15)',
      transition: 'all 150ms ease 0s',
      alignItems: 'center',
      position: 'fixed',
      bottom: '0',
      zIndex: 10,
      display: 'flex',
      justifyContent: 'space-between',
    },
  });

export default cookieBarStyle;
