import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

import { footerStyle } from './FooterStyle';

const useStyles = makeStyles(footerStyle);

interface FooterProps {
  theme: 'dark' | 'white' | 'transparent';
  big?: boolean;
  content: object;
  className?: string;
}

export const Footer: React.FC<FooterProps> = ({ big, children, content, theme, className }) => {
  const classes = useStyles();
  const themeType = theme === 'transparent' || theme == undefined ? false : true;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes[theme as 'dark' | 'white']]: themeType,
    [classes.big]: big || children !== undefined,
    [className!]: className !== undefined,
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        {children !== undefined ? (
          <div>
            <div className={classes.content}>{children}</div>
            <hr />
          </div>
        ) : (
          ' '
        )}
        {content}
        <div className={classes.clearFix} />
      </div>
    </footer>
  );
};
